.Background
{
    text-align:center;
    background-image: url(../../Assets/BackGroundImage.png);
    position:fixed;
    min-width:100%;
    min-height:100%;
    background-repeat: no-repeat;
    background-size:cover;  


}

@font-face{
    src: url(../../Assets/Fw__Otis_FONT_Pack/Helvica/HelveticaNeue-Regular_22623.ttf);
    font-family: HelveticalNeue;
}





@font-face{
    src: url(../../Assets/Fw__Otis_FONT_Pack/Grifo/Grifo\ M\ Bold.otf);
    font-family: GrifoM;
}

@font-face{
    src: url(../../Assets/Fw__Otis_FONT_Pack/Grifo/Grifo\ M\ Bold.ttf);
    font-family: GrifoMBold;
}

@font-face{
    src: url(../../Assets/Fw__Otis_FONT_Pack/avenir-lt-std-cufonfonts/AnyConv.com__AvenirLTStd-Black.ttf);
    font-family: AvenirBlack;
}


.otis-one-logo{
    font: normal normal bold 50px/59px HelveticalNeue;
    color: #FFFFFF;
}

.kpi-text{
top: 0px;
left: 26px;
width: 355px;
height: 100px;
text-align: left;
display: flex;
font: normal normal bold 50px/59px GrifoMBold ;
letter-spacing: 0px;
color: #FFFFFF;
text-transform: uppercase;
align-items: flex-start;

font-weight: bold;
}

.btn-text{

 
border: 1px solid white; ;


}



.btn-outline-primary{
    
    
top: 400px;
left: 44px;
width: 104px;
height: 34px;
font: normal normal medium 12px/16px Avenir;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
border-radius: 0px;

     
     
}

 .btn-outline-primary:hover{
    background-color:  #CFA152;
    color : white;
} 

