* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

header {
  height: 12vh;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color:white;
  font-size: 13px;
  /* width: 100vw; */
  }

.nav-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 10px;
  padding-left: 30px;
  height: 12vh;
  background-color:#0633A3;
}

.left-aligned{
  display: flex;
  position: relative;
  align-items: center;
}
.right-aligned{
  display: flex;
  /* position:relative; */
  align-items:center;
  gap: 1.5rem;
  margin-top: 0.5rem;
}

.logo-text{
  margin-right:50px;
  aspect-ratio:110/39;
  height:100%;
  margin-top: 0px;
  
} 

.menus {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  margin-bottom: 0rem;
  
}

.menu-items {
  margin-right: 12.5px;
  font-size: 14px;
  color:black;
  position: relative;
  
}

.menu-items a {
  display: block;
  font-size: inherit;
  
  
  color:black;
  text-decoration: none;
  text-align: left;
  padding: 0.4rem 2rem;
  
  
}

.menu-items button {
  color:black;
  font-size: inherit;
  
  
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  position: relative;

  /* new additions */
  display: flex;
  justify-content: left;
  align-items: center;
}

.menu-items a
{
  text-align: left;
  padding: 0.4rem 2rem;
  color:black
}
.menu-items button {
  text-align: left;
  padding: 0.4rem 2rem;
  color:black
}

/* new addition */
.menu-items button a {
  padding: 0;
}

 /* .menu-items a:hover, */
.menu-items button:hover {
  background-color: #0633A3;
} 

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
  color:white
}

.dropdown {
  position: absolute;
  right: auto;
  left:0px;

  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 2px;
  
  z-index: 9999;
   width: 15rem;
   
  padding: 0rem 0rem;
  list-style: none;
  background-color:white;
  /* border-radius: 0.5rem; */
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;

}

/* new addition */

.arrow.custom {
  display: none;
}
@media (min-width: 960px) {
  .arrow.custom {
    display: block;
  }
}
.header{
  display: flex;
  justify-content:center;
  align-items :center;
  margin-top: 30px;
}
.loader_style{
  display:flex;
  flex:1;
  align-items: center;
  justify-content:center;
  position: absolute;
  top:50%;
  left:50%
}
.arrow_bracket
{
  position:relative;
  left : 50px;
  font-size: 20px;
}
.arrow_bracket_ID
{
  position:relative;
  left : 20px;
  font-size: 15.5px; 
  color:black
}
.items_a
{
  color:white
}
.button-menu{
font-size: 32px;
color:white
}

.dropdowncustom {
  position: absolute;
  right: auto;
  left:0px;

  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 2px;
  
  z-index: 9999;
   width: 17.5rem;
   
  padding: 0rem 0rem;
  list-style: none;
  background-color:white;
  /* border-radius: 0.5rem; */
  display: none;
}

.dropdowncustom.show {
  display: block;
}

.dropdowncustom .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;

}


 