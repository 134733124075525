  .button_style{
    height:55px;
    display: flex;
    justify-content: flex-end;
  }
 
.para-style{
    font-size: small;
}
 .userinfo{
    display: flex;
    flex-direction: column;
    margin-top: -2px;
    font-weight: bold; 
    
 }
    

    
 