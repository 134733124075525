.BackImage
{
    text-align:center;
    background-image: url(../../Assets/ErrorBackgroundImage.png);
    position:fixed;
    min-width:100%;
    min-height:100%;
    background-repeat: no-repeat;
    background-size:cover;  
}
@font-face{
    src: url(../../Assets/Fw__Otis_FONT_Pack/Helvica/HelveticaNeue-Regular_22623.ttf);
    font-family: HelveticalNeue;
}


@font-face{
    src: url(../../Assets/Fw__Otis_FONT_Pack/Grifo/Grifo\ M\ Bold.otf);
    font-family: GrifoM;
}

@font-face{
    src: url(../../Assets/Fw__Otis_FONT_Pack/Grifo/Grifo\ M\ Bold.ttf);
    font-family: GrifoMBold;
}

@font-face{
    src: url(../../Assets/Fw__Otis_FONT_Pack/avenir-lt-std-cufonfonts/AnyConv.com__AvenirLTStd-Black.ttf);
    font-family: AvenirBlack;
}

.welcome-text-error{
    
    top: 189px;
    left: 78px;
    width: 579px;
    height: 70px;
    text-align: left;
    font: normal normal bold 32px Avenir;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 45px;
}

.kpi-text-error{
     
    font-family: Avenir!important;
    line-height: 0.6;
    font-size: 21px;
    color: #FFFFFF; 
    margin-left: 45px;
    height: 70px;

}

.btn-text-error{
    font: 18px/10px AvenirBlack;
    padding: 0 !important;
    color:#CFA152 ;
}

.btn-text-error:link {
text-align: left;
text-decoration: underline;
font: normal normal normal 23px/31px Avenir;
letter-spacing: 0px;
color: #CFA152;
margin-right: 5px;

  }
  
.btn-text-error:visited {
    text-decoration: none;
  }
  
.btn-text-error:hover {
    text-decoration: underline;
    color:#CFA152 ;
  }
  
.btn-text-error:active {
    text-decoration: none;
  }

